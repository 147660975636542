<template>
    <div class="setting-telephone">
        <edit :fs="fs" api="settings" id="telephone" title="Telephone Setting"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
export default {
    components: {
        Edit
    },
    computed: {
        fs() {
            return [
                //
                { heading: "Telephone", data: "value.telephone", type: "text" },
                { heading: "Show", data: "value.show", type: "boolean", default: true }
            ];
        }
    }
};
</script>
<style lang="less">
.setting-telephone {
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
}
</style>


